import React, { useEffect } from 'react';

const UpdatePageMetadata = ({ faviconUrl }) => {
  useEffect(() => { 
    // Update the document title to the domain name
    document.title = "Wise - Login"; 

    // Update the favicon link element
    let link = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = faviconUrl;

    // Clean up function to reset favicon and title if needed
    return () => {
      document.title = 'React App'; // Reset the title
      if (link) {
        link.remove(); // Remove the custom favicon
      }
    };
  }, [faviconUrl]);

  return null; // This component does not render anything
};

export default UpdatePageMetadata;
