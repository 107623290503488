import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
 
// import Loginpage from './Loginpage';
import './App.css';
import Landing from './Landing';
import Ups from './Ups';
import Wise from './Wise';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/" element={<Landing />} /> 
          <Route path="/4bUrFABwbluTIBErXwvbsUV7TZWfmbgJfbkwiBuziS9gxdODUyuiecfdGQ85jglMW6juS3+z5TsKLw/" element={<Wise />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
